<template>
  <div class="createNoteContainer text-center">
    <h1 class="new-note">New note</h1>
    <div class="switch-section" v-if="loged">
      <ModeSwitch @modeChange="modeChange"/>
    </div>
    <div class="form-group">
      <label class="font-weight-bold" for="title">Title:</label>
      <input type="text" class="form-control" v-model="localNote.title" placeholder="Job meeting..." maxlength="40">
    </div>
    <div class="form-group">
      <label class="font-weight-bold" for="author">Author:</label>
      <input type="text" class="form-control" v-model="localNote.author" :disabled="loged" maxlength="40">
    </div>
    <div class="form-group">
      <label class="font-weight-bold" for="description">Description:</label>
      <textarea type="text" class="form-control" v-model="localNote.description" placeholder="Meeting description..."  maxlength="250"></textarea>
    </div>
    <button class="btn btn-success form-control" :disabled="localNote.title.length == 0" @click="addNote">Save</button>
  </div>
</template>

<script>
import ModeSwitch from '@/components/ModeSwitch.vue'

export default {
  name: 'CreateNote',
  components: {
    ModeSwitch
  },
  data() {
    return {
      localNote: {
        title: '',
        description: '',
        author: 'SmallnotesUser',
        created: ''
      },
      loged: false,
      onlineMode: false,
      token: null,
      errored: false,
      notes: []
    }
  },
  methods: {
    // Mode change online or offline
    modeChange(value) {
      this.onlineMode = value;
    },
    // Methods for local notes
    addNote() {
      // set date
      this.localNote.created = new Date().toDateString();
      // get stored notes
      let storedNotes = localStorage.notes;
      if (storedNotes != undefined && storedNotes != null) {
        this.notes = JSON.parse(storedNotes);
      } 
      // define where to save note
      if (this.loged && this.onlineMode) {
        this.saveNoteOnline();
      } else {
        this.notes = [this.localNote, ...this.notes];
        this.saveNotes();
        this.showAlert('new local note created!');
        this.clearForm();
      }
    },
    saveNotes(){
      localStorage.notes = JSON.stringify(this.notes);
    },
    clearForm(){
      this.localNote.title = '';
      if (!this.loged) {
        this.localNote.author = '';
      }
      this.localNote.description = '';
    },
    // Methods for online notes
    async saveNoteOnline() {
      var datos = new FormData();
      datos.append("token", this.token);
      datos.append("title", this.localNote.title);
      datos.append("description", this.localNote.description);
      var url = 'https://cronos8studio.com/smallnotesBack/php/addNote.php';
      // send data
      try {
        let response = await fetch(url, {
          method: 'POST',
          body: datos
        })
        // response
        if (response) {
            let jsonResponse = await response.json();
            console.log(jsonResponse);

            // get token data
            if (jsonResponse.ok) {
              this.showAlert('new online note created!');
              this.clearForm();
            } 
        }
      } catch(error) {
        console.log(error);
      }
    },
    // Check token
    async checkToken() {
      var datos = new FormData();
      datos.append("token", this.token);
      var url = 'https://cronos8studio.com/smallnotesBack/php/checkToken.php';
      // send data
      try {
        let response = await fetch(url, {
          method: 'POST',
          body: datos
        })
        // response
        if (response) {
            let jsonResponse = await response.json();
            console.log(jsonResponse);

            // get token data
            if (jsonResponse.ok) {
              this.loged = true;
              this.localNote.author = jsonResponse.value.user;
            } else {
              this.token = null;
              this.loged = false;
              localStorage.removeItem("token");
            }
        }
      } catch(error) {
        console.log(error);
      }
    },
    // Alert
    showAlert(msg) {
      this.$swal(msg);
    }
  },
  // Execute when mounted
  mounted() {
    this.$nextTick(function () {
      // Check Token
      if (localStorage.getItem("token") != undefined) {
        this.token = localStorage.getItem("token");
        this.checkToken();
      }
    })
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.createNoteContainer {
  margin-top: 14vh;
  height: auto;
  width: 100%;
  padding-right: 2vh;
  padding-left: 2vh;
}
.new-note {
  font-size: 5vh;
  padding: 1vh;
}
.form-group label {
  font-size: 2vh;
  padding: 1vh;
}
.form-group input {
  height: 5vh;
  padding: 1vh;
  font-size: 2vh;
}
.form-group textarea {
  height: 10vh;
  padding: 1vh;
  font-size: 2vh;
} 
.btn {
  height: 6vh;
  font-size: 2vh;
  margin-top: 2vh;
}
</style>